<template>
  <!-- "top bar" --NOTE: leave this here for notices that need to be posted quickly!!!! -->
  <!-- Note: add the following to the body tag " showing-top-bar-info-notice" -->
  <div
    id="info-notice"
    class="top-bar-notice"
  >
    <strong>Notice:</strong> &nbsp;
    Reporting data is currently delayed by 2 days. We are actively working on a solution.
  </div>

  <!-- Status.io message area -->
  <div
    id="status-notice"
    class="top-bar-notice"
  >
  </div>

  <div
    id="limited-trial-add-payment-notice"
    class="top-bar-notice"
  >
    <strong>Don't forget:</strong>
    Links are disabled until you add payment info. <a
      class="button"
      href="/account/billing/payment-info?cc-show=1"
    >Activate your links...</a>
  </div>

  <div
    id="add-payment-notice"
    class="top-bar-notice warning"
  >
    <strong>Don't forget:</strong>
    <!--  been through limited access trial with disabled links, so links will continue to be disabled -->
    @if (!Session.Plan.RequiresPaymentMethodOnFileDuringTrial)
    {
    <span>Links are disabled until payment info is provided. <a href="/account/billing/payment-info?cc-show=1"> Enable your links now...</a></span>
    }
    else
    {
    // assume added payment info to pass paywall at some point
    <span><a href="/account/billing/payment-info?cc-show=1">Add payment info now</a> to prevent an interruption in service.</span>
    }
  </div>

  <div
    id="overdue-payment-notice"
    class="top-bar-notice warning"
  >
    <strong>Payment due:</strong>
    Please <a href="/account/billing/payment-info">update your payment info</a> to avoid service disruption.
  </div>

  <div
    id="bad-email-notice"
    class="top-bar-notice warning"
  >
    <strong>New email?</strong> The address on file has recently bounced.
    <a href="/account/profile">Update it</a>
  </div>

  <div
    id="delinquent-account-notice"
    class="top-bar-notice warning"
  >
    <strong>Your account has been locked due to non payment</strong>
    Please <a href="/account/billing/payment-info">add a credit card</a>.
    <!-- or <a href="mailto:help@geni.us?subject=Help: Delinquent Account">contact us</a> -->
    <!-- to get things squared away. -->
  </div>
  <div
    id="delinquent-but-processing-cc"
    class="top-bar-notice"
  >
    <strong>Hang tight, we are processing your payment.</strong>
    This can take a couple of minutes.
  </div>
  <div
    id="invalid-creditcard-notice"
    class="top-bar-notice warning"
  >
    <strong>Invalid or expired:</strong>
    There's a problem with your card. <a href="/account/billing/payment-info">Fix it now...</a>
  </div>
  <div
    id="account-closed-notice"
    class="top-bar-notice warning"
  >
    <strong>This account has been closed.</strong>
    To re-open your account, please <a href="/account/billing/payment-info">add a card</a>.
  </div>
  <div
    id="child-account-closed-notice"
    class="top-bar-notice warning"
  >
    <strong>This account has been closed.</strong>
  </div>

</template>
